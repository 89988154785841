import * as React from 'react';
import PropTypes from 'prop-types';
import {AppBar} from '@mui/material';
import {Toolbar} from '@mui/material';
import {Typography} from '@mui/material';
import {CssBaseline} from '@mui/material';
import {useScrollTrigger} from '@mui/material';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
import {Slide} from '@mui/material';
// import { createMuiTheme } from "@material-ui/core/styles";
// import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from '@mui/styles';

// import {useState, useEffect} from "react";

function  HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  abRoot: {
    backgroundColor: "red"
  },
  abStatic: {
    border: "solid blue 2px"
  }
})
);

export default function HideAppBar(props) {



  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar sx={{ bgcolor: "#00897b" }} id="app-bar">
          <Toolbar>
            <Typography variant="h6" component="div">
              Licencia Deportiva
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {/* <Container>
        <Box sx={{ my: 2 }}>
          {[...new Array(12)]
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
            )
            .join('\n')}
        </Box>
      </Container> */}
    </React.Fragment>
  );
}
