import { createContext, useContext, useEffect, useState } from "react";

import {Card} from '@mui/material';
import {CardContent} from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
import {Typography} from '@mui/material';
import { Button, CardActionArea, CardActions, Divider } from '@mui/material';

import { useUserAuth } from "../context/UserAuthContext.mjs";

import { useNavigate } from 'react-router-dom';

const TarjetaMontana = ({ auth, props }) => {

  // const { User } = useUserAuth();
  // const usuario = User();

  const { User } = useUserAuth();

  const [dataTarjeta, setData] = useState({});
  
  const navigate = useNavigate();

  
  useEffect(() => {

    //wait for user to be logged in
    User().then(async (usuario) => {

      if (usuario) {

        usuario.getIdToken().then((idToken) => {
          //console.log(idToken);

          //save token in localstorage
          localStorage.setItem('token', idToken);

          //fetch data from api
          let url = '';
          const apiDeportesAereos = 'https://fbapi.licenciadeportiva.app/montana';
          url = apiDeportesAereos + '?token=' + idToken;

          //fetch data from api cors
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
          }).then((response) => {
            return response.json();
          }).then((data) => {
            console.log(data);
            setData(data);
          }).catch((error) => {
            console.log(error);
          });


          // fetch(url).then((response) => {
          //   response.json().then((data) => {
          //     //console.log(data);

          //     setData(data);
          //   });
          // });
        });
      }

      //if not logged in, redirect to /phoneSignUp
      else {
        //window.location.href = '/phoneSignUp';

        //redirect to /phoneSignUp
        //props.history.push('/phoneSignUp');
        navigate('/phoneSignUp');
      }

      //usuario get notification token
      // usuario.
    });
    

  }, []);

//if dataTarjeta is empty, tieneMontana is false
const tieneMontana = Object.keys(dataTarjeta).length > 0;
localStorage.setItem('tieneMontana', tieneMontana);




const tj = [];

for (let i = 0; i < dataTarjeta.length; i++) {
  const element = dataTarjeta[i];
  //console.log(element);
  tj.push(
    // <Card sx={{ maxWidth: 345 }} key="{i}">
    <Card key="Montana{i}">
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="140"
          image="https://www.licenciadeportiva.app/wp-content/uploads/2021/08/Deportes-Aereos-1.jpg"
          alt="green iguana"
        /> */}
        <CardContent>
          <div style={{textAlign: 'center'}}>
          <img src="/logos/logo-montana.png" style={{ width: "5em" }} />
          <img src="/logos/logo-reaj.png" style={{ width: "5em" }} />
        {(() => {
          if (element.tarjeta === 'FEDME') {
            return <><img src="/logos/logo-montana-fedme.png" style={{ width: "5em" }} /><img src="/logos/logo-montana-reprocidad-refugios.png" style={{ width: "5em" }} /></>;
          }
        })()}
        </div>
        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: "center", fontSize: "1rem"}}>
            FEDERACIÓN NAVARRA DE DEPORTES DE MONTAÑA Y ESCALADA
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.nombre} {element.apellidos}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            Nº Licencia:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.dni}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;&nbsp;F. Nacim.:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.fechaNacimiento}
          </Typography>
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            CLUB:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.club}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            TARJETA:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.tarjeta}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary"
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            MODALIDAD: 
          </Typography>
          <Typography variant="body2" color="text.secondary"
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.modalidad}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            CATEGORÍA:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.categoria}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            SUPLEMENTOS:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.suplementos}
          </Typography>
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.linea1}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}


      return (
        <>
          {tj}
        </>
      );

}

  
export default TarjetaMontana;