import { createContext, useContext, useEffect, useState } from "react";

import {Card} from '@mui/material';
import {CardContent} from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
import {Typography} from '@mui/material';
import { Button, CardActionArea, CardActions, Divider, Box } from '@mui/material';

import { useUserAuth } from "../context/UserAuthContext.mjs";

import { useNavigate } from 'react-router-dom';

const TarjetaDeportesAereos = ({ auth, props }) => {

  // const { User } = useUserAuth();
  // const usuario = User();

  const { User } = useUserAuth();

  const [dataTarjeta, setData] = useState({});
  
  const navigate = useNavigate();

  
  useEffect(() => {

    //wait for user to be logged in
    User().then(async (usuario) => {

      if (usuario) {

        usuario.getIdToken().then((idToken) => {
          //console.log(idToken);

          //save token in localstorage
          localStorage.setItem('token', idToken);

          //fetch data from api
          let url = '';
          const apiDeportesAereos = 'https://fbapi.licenciadeportiva.app/deportesAereos';
          url = apiDeportesAereos + '?token=' + idToken;

          //fetch data from api cors
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
          }).then((response) => {
            return response.json();
          }).then((data) => {
            console.log(data);
            setData(data);
          }).catch((error) => {
            console.log(error);
          });




          
        });
      }

      //if not logged in, redirect to /phoneSignUp
      else {
        //window.location.href = '/phoneSignUp';

        //redirect to /phoneSignUp
        //props.history.push('/phoneSignUp');
        navigate('/phoneSignUp');
      }

      //usuario get notification token
      // usuario.
    });
    

  }, []);

  //if dataTarjeta is empty tieneDeportesAereos is false

  console.log('miro a ver si tiene de deportes aereos');

  const tieneDeportesAereos = Object.keys(dataTarjeta).length > 0;
  localStorage.setItem('tieneDeportesAereos', tieneDeportesAereos);

  console.log(tieneDeportesAereos);


      const tj = [];

for (let i = 0; i < dataTarjeta.length; i++) {
  const element = dataTarjeta[i];

  let key = "a" + i;

  //console.log(element);
  tj.push(
    // <Card sx={{ maxWidth: 345 }} key="{i}">
    <Card key="{i}">
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="140"
          image="https://www.licenciadeportiva.app/wp-content/uploads/2021/08/Deportes-Aereos-1.jpg"
          alt="green iguana"
        /> */}
          <div style={{textAlign: 'center'}}>
            <img src="/logos/logo-aereos.png" style={{ width: "5em" }} />
          </div>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: "center", fontSize: "1rem"}}>
            FEDERACIÓN NAVARRA DE DEPORTES AEREOS
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.nombre} {element.apellidos}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            Nº Licencia: {element.dni}
          </Typography>
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            CLUB:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.club}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            ESPECIALIDAD:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.especialidad}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary"
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            MODALIDADES: 
          </Typography>
          <Typography variant="body2" color="text.secondary"
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.modalidades}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            FECHA EXPEDICIÓN:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.fechaExpedicion}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            HABILITACIÓN RFAE:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.habilitacionRdae ? 'SÍ' : 'NO'}
          </Typography>
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            Nº Poliza Accidentes (Allianz): 048180490
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            Nº Poliza RC (Millenium): ESDV2103058
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


      return (
        <>
          {tj}
        </>
      );

  // return (
  //   <Card sx={{ maxWidth: 345 }}>
  //     <CardActionArea>
  //       <CardMedia
  //         component="img"
  //         height="140"
  //         image="/static/images/cards/contemplative-reptile.jpg"
  //         alt="green iguana"
  //       />
  //       <CardContent>
  //         <Typography gutterBottom variant="h5" component="div">
  //           {dataTarjeta.nombre}
  //         </Typography>
  //         <Typography variant="body2" color="text.secondary">
  //           Lizards are a widespread group of squamate reptiles, with over 6,000
  //           species, ranging across all continents except Antarctica
  //         </Typography>
  //       </CardContent>
  //     </CardActionArea>
  //     <CardActions>
  //       <Button size="small" color="primary">
  //         Share
  //       </Button>
  //     </CardActions>
  //   </Card>
  // );
  //   } else {
  //       return (
  //           <div>
                
  //           </div>
  //       )



    }

export default TarjetaDeportesAereos;