import { createContext, useContext, useEffect, useState } from "react";

import {Card} from '@mui/material';
import {CardContent} from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
import {Typography} from '@mui/material';
import { Button, CardActionArea, CardActions, Divider } from '@mui/material';

import { useUserAuth } from "../context/UserAuthContext.mjs";

import { useNavigate } from 'react-router-dom';

const TarjetaTaekwondo = ({ auth, props }) => {

  // const { User } = useUserAuth();
  // const usuario = User();

  const { User } = useUserAuth();

  const [dataTarjeta, setData] = useState({});
  
  const navigate = useNavigate();

  
  useEffect(() => {

    //wait for user to be logged in
    User().then(async (usuario) => {

      if (usuario) {

        usuario.getIdToken().then((idToken) => {
          //console.log(idToken);

          //save token in localstorage
          localStorage.setItem('token', idToken);

          //fetch data from api
          let url = '';
          const apiDeportesAereos = 'https://fbapi.licenciadeportiva.app/taekwondo';
          url = apiDeportesAereos + '?token=' + idToken;

          //fetch data from api cors
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
          }).then((response) => {
            return response.json();
          }).then((data) => {
            console.log(data);
            setData(data);
          }).catch((error) => {
            console.log(error);
          });

        });
      }

      //if not logged in, redirect to /phoneSignUp
      else {
        //window.location.href = '/phoneSignUp';

        //redirect to /phoneSignUp
        //props.history.push('/phoneSignUp');
        navigate('/phoneSignUp');
      }

      //usuario get notification token
      // usuario.
    });
    

  }, []);

  //if dataTarjeta is empty tieneTaekwondo is false
  const tieneTaekwondo = Object.keys(dataTarjeta).length > 0;
  localStorage.setItem('tieneTaekwondo', tieneTaekwondo);

      const tj = [];

for (let i = 0; i < dataTarjeta.length; i++) {
  const element = dataTarjeta[i];
  //console.log(element);
  tj.push(
    // <Card sx={{ maxWidth: 345 }} key="{i}">
    <Card key="Taekwondo{i}">
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="140"
          image="https://www.licenciadeportiva.app/wp-content/uploads/2021/08/Deportes-Aereos-1.jpg"
          alt="green iguana"
        /> */}
          <div style={{textAlign: 'center'}}>
            <img src="/logos/logo-taekwondo.png" style={{ width: "5em" }} />
            <img src="/logos/logo-taekwondo-esp.png" style={{ width: "5em" }} />
          </div>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: "center", fontSize: "1rem"}}>
            FEDERACIÓN NAVARRA DE TAEKWONDO
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.nombre} {element.apellido1} {element.apellido2}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            Nº Licencia:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.numeroLicencia}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            F. Licencia:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.fechaLicencia}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;&nbsp;Válida hasta el:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "center" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;30/09
          </Typography>
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            CLUB:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.club}
          </Typography>
          <Typography />
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            CINTURÓN:
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "grey", textAlign: "left" ,textTransform: "uppercase", display: "inline-block"}}>
            &nbsp;{element.cintoNombre} ({element.fechaCinto})
          </Typography>
          <Typography />
          <Divider sx={{color: "#00897b", marginTop: '1%', marginBottom: '1%'}} >
          </Divider>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.linea1}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.linea2}
          </Typography>
          <Typography variant="body2" color="text.secondary" 
            sx={{color: "#00897b", textAlign: "center" ,textTransform: "uppercase"}}>
            {element.linea3}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


      return (
        <>
          {tj}
        </>
      );

  // return (
  //   <Card sx={{ maxWidth: 345 }}>
  //     <CardActionArea>
  //       <CardMedia
  //         component="img"
  //         height="140"
  //         image="/static/images/cards/contemplative-reptile.jpg"
  //         alt="green iguana"
  //       />
  //       <CardContent>
  //         <Typography gutterBottom variant="h5" component="div">
  //           {dataTarjeta.nombre}
  //         </Typography>
  //         <Typography variant="body2" color="text.secondary">
  //           Lizards are a widespread group of squamate reptiles, with over 6,000
  //           species, ranging across all continents except Antarctica
  //         </Typography>
  //       </CardContent>
  //     </CardActionArea>
  //     <CardActions>
  //       <Button size="small" color="primary">
  //         Share
  //       </Button>
  //     </CardActions>
  //   </Card>
  // );
  //   } else {
  //       return (
  //           <div>
                
  //           </div>
  //       )



    }


export default TarjetaTaekwondo;