import React, { useEffect, useState } from "react";
import TarjetaDeportesAereos from "../tarjetas/DeportesAereos.mjs";
import {Box} from "@mui/system";
import TarjetaMontana from "../tarjetas/Montana.mjs";

import { Grid } from '@mui/material';
import TarjetaTaekwondo from "../tarjetas/Taekwondo.mjs";
import { InstallPWA } from "./InstallPWA.mjs";

import { AddToHomeScreen } from "react-pwa-add-to-homescreen";

const Home =  (props) => {


  const [marginTop, setMarginTop] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    //Navegación superior
    const appBar = document.getElementById("app-bar");
    const appBarHeight = appBar.clientHeight;

    //console.log("appBarHeight", appBarHeight);

    setMarginTop(appBarHeight);


    //Navegación inferior
    const bottomNav = document.getElementById("bottom-nav");
    const bottomNavHeight = bottomNav.clientHeight +10;

    //console.log("bottomNavHeight", bottomNavHeight);

    setMarginBottom(bottomNavHeight);

  }, []);


  const marginTopinPx = marginTop + "px";
  const marginBottominPx = marginBottom + "px";
    

  return (
    <>
    {/* <InstallPWA /> */}
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      // minHeight="140vh"
      // marginTop={marginTopinPx}
      
    >
      <Grid container spacing={2} marginTop={marginTopinPx} marginBottom={marginBottominPx}>
        <Grid item xs={12}>
          <TarjetaDeportesAereos />
        </Grid>
        <Grid item xs={12}>
          <TarjetaMontana />
        </Grid>
        <Grid item xs={12}>
          <TarjetaTaekwondo />
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default Home;
