import React from 'react';




const SeguroDeportesAereos = () => {
    return (
        <div className="p-4 box margintop-1">
        <h1 className="mb-3 texto-seguro center">ACCIDENTES FEDERACIÓN NAVARRA DE DEPORTES ARÉREOS</h1>
        <hr />
        <p>Nº de póliza de Accidentes 2022: <strong>048180490</strong></p>
        <p>Compañía: Allianz</p>
        <p>Tfno: 900 404 444</p>
        <p></p>
        <p><b>RESPONSABILIDAD CIVIL</b></p>
        <hr />
        <p>Nº de póliza Responsabilidad Civil 2022: <strong>ESDV2103058</strong></p>
        <p>Compañía: MIC Insurance (Millenium Insurance Company)</p>
        <p>Declaración siniestro RC: Enviar mail a __________ con copia a siniestros@fnda.es</p>
        <p></p>
        <p>En caso de siniestro (de cualquier tipo) es obligatorio dar parte a la federación: siniestros@fnda.es,  sin perjuicio de cumplir las normas respectivas de cada póliza de seguros.</p>
        <br />
        <p><a href="https://www.fnda.es/contenido?parent=licencias">Más información</a></p>
        </div>
    )
}

export default SeguroDeportesAereos;