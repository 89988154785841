import * as React from 'react';

import { useState } from "react";
import { makeStyles } from '@mui/styles';
import { useMatch, useNavigate } from "react-router-dom";

import {Box} from '@mui/material';
import {BottomNavigation} from '@mui/material';
import {BottomNavigationAction} from '@mui/material';
import {MedicalServices} from '@mui/icons-material';
import {Settings} from '@mui/icons-material';

import {CardMembership} from '@mui/icons-material';
// import PhoneEnabled from '@mui/icons-material/PhoneEnabled';


import {Paper} from '@mui/material';
const useStyle = makeStyles((theme) => ({
    root: {
      width: "100%",
      position: "fixed",
      bottom: 0
    }
  }));



const navigationData = [
    {
      label: "Tarjetas",
      value: "/",
      icon: <CardMembership />,
    },
    // {
    //   label: "Teléfono",
    //   value: "phonesignup",
    //   icon: <PhoneEnabled />
    // },
    {
      label: "Seguros",
      value: "listaseguros",
      icon: <MedicalServices />
    },
    {
      label: "Configuración",
      value: "configuracion",
      icon: <Settings />
    }

    // {
    //   label: "Actualizar",
    //   value: "actualizar",
    //   icon: <LocationOnIcon />
    // }
  ];
  




const SimpleBottomNavigation = (props) => {
//   const [value, setValue] = React.useState(0);

const classes = useStyle();
let navigate = useNavigate();
const [value, setValue] = useState("");
const handleChange = (event, newValue) => {
    setValue(newValue);
    // history.push(newValue);
    navigate(newValue);
  };


  //set default icon active
React.useEffect(() => {
  if (!value) {
    setValue(navigationData[0].value);
  }
}, [value]);


  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
      component={Box}
      boxShadow={5}
      id="bottom-nav"
    >
      {navigationData.map((item, index) => (
        <BottomNavigationAction key={index} {...item} />
      ))}
    </BottomNavigation>
    </Paper>
  );
}

export default SimpleBottomNavigation;