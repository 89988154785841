import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  sendEmailVerification,
  reauthenticateWithCredential,
  EmailAuthProvider,
  delete as deleteAccount,
  FacebookAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  PhoneAuthProvider,
  UserCredential,
  User,
  Auth,
  AuthProvider,
  AdditionalUserInfo,
  ActionCodeSettings,
  ActionCodeInfo,
  Persistence,
  IdTokenResult,
  UserInfo,

} from "firebase/auth";

import {getMessaging, getToken, onMessage, onBackgroundMessage, onTokenRefresh, deleteToken} from "firebase/messaging";

import { auth } from "../firebase.mjs";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }


  // function User () {
  //   return user;
  // }


  //User return promise
  function User() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
          resolve(user);
        } else {
          setUser(null);
          resolve(null);
        }
      });
    });
  }

  //Userinfo
  function UserInfo() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
          resolve(user);
        } else {
          setUser(null);
          resolve(null);
        }
      });
    });
  }


  // const getIdToken = async () => {
  //   let idToken = await AsyncStorage.getItem("id_token")
  //   const expiration = await AsyncStorage.getItem("expiration")
   
  //   if (Number(expiration) > moment().unix()) {
  //    return idToken
  //   }
   
  //   idToken = await firebase.auth().currentUser.getIdToken(true)
  //   // console.log(idToken)
   
  //   await AsyncStorage.setItem("id_token", idToken)
  //   await AsyncStorage.setItem("expiration", `${moment().unix() + 60 * 60}`)
   
  //   return idToken
  //  }




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (currentuser) {
        setUser(currentuser);
      } else {
        setUser(null);
      }
      //console.log("Auth", currentuser);


      setUser(currentuser);
    });
    

    return () => {
      unsubscribe();
    };
  }, []);



  


  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        setUpRecaptha,
        User,
        UserInfo,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {

  // console.log("entro en useUserAuth");

  return useContext(userAuthContext);
}
