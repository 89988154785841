import React from 'react';




const SeguroMontana = () => {
    return (
        <div className="p-4 box margintop-1">
        <h1 className="mb-3 texto-seguro center">FEDERACIÓN NAVARRA DE DEPORTES DE MONTAÑA Y ESCALADA</h1>
        <hr />
        <p><strong>¿CÓMO ACTUAR EN CASO DE ACCIDENTE?</strong></p>
        <hr />
        <p><strong>NOTA IMPORTANTE DE OBLIGADO CUMPLIMIENTO</strong></p>
        <p>Dar parte de accidente en el plazo de 72 horas llamando al 696 907374 y te indicarán qué hacer.</p>
        <p>En el caso de suceder el accidente en el extranjero, debes ponerte en contacto con ASITUR en el Tfno. 0034 91 393 90 30, tan pronto haya ocurrido y recibirá instrucciones detalladas sobre el protocolo de actuación que se va a seguir según las circunstancias.</p>
        <br />
        <p><a href="https://www.nafarmendi.org/files/fnmontana/Documentos/Licencia%20Federativa/2020/que%20hacer%20en%20caso%20accidente.pdf">Más</a></p>
        </div>
    )
}

export default SeguroMontana;