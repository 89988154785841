import React from 'react';




const SeguroTaekwondo = () => {
    return (
        <div className="p-4 box margintop-1">
        <h1 className="mb-3 texto-seguro center">FEDERACIÓN NAVARRA DE TAEKWONDO Y D.A.</h1>
        <hr />
        <p><strong>CÓMO ACTUAR EN CASO DE ACCIDENTE?</strong></p>
        <hr />
        <p>Pulsando el botón accederás a la web de la Federación con toda la documentación.</p>
        <br />
        <p><a href="https://www.taekwondonavarra.com/contenido/compa%C3%A3%C6%92%C3%A2%E2%80%98ia-aseguradora-imq">Más</a></p>
        </div>
    )
}

export default SeguroTaekwondo;