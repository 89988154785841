import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route} from "react-router-dom";
import "./App.css";
import Home from "./components/Home.mjs";
import Signup from "./components/Signup.mjs";
import PhoneSignUp from "./components/PhoneSignUp.mjs";
import ProtectedRoute from "./components/ProtectedRoute.mjs";
import { UserAuthContextProvider } from "./context/UserAuthContext.mjs";

import HideAppBar from "./layout/HideAppBar.mjs";

import { CssBaseline } from "@mui/material";

import SimpleBottomNavigation from "./layout/SimpleBottomNavigation.mjs";

import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

import { useState } from "react";
 import { getTokenvalue} from "./firebase.mjs";

  //import service worker
import ListaSeguros from "./seguros/ListaSeguros.mjs";
import SeguroDeportesAereos from "./seguros/SeguroDeportesAereos.mjs";
import SeguroMontana from "./seguros/SeguroMontana.mjs";
import SeguroTaekwondo from "./seguros/SeguroTaekwondo.mjs";
import Configuración from "./components/Configuracion.mjs";
  

//https://github.com/dmalvia/React_Firebase_Auth_Tutorial/tree/phone-auth

function App() {

  const [setTokenFound] = useState(false);

  getTokenvalue(setTokenFound);
  // onMessageListener();



  // //onMessageListener is a custom hook that uses the onMessage() method of the messaging service to listen for incoming messages.
  // //When a message is received, the callback function is executed and the message is set to the notification state variable.
  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       setNotification({
  //         title: payload.notification.title,
  //         body: payload.notification.body,
  //       });
  //       setShow(true);
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // }, []);
  

  //console.log('setTokenFound: ' + setTokenFound);


  // useEffect(() => {
  //   //console.log('useEffect');
  //   //console.log('isTokenFound: ' + isTokenFound);
  //   if (isTokenFound) {
  //     console.log('isTokenFound: ' + isTokenFound);
  //     const messaging = getMessaging();
  //     onMessageListener()
  //       .then((payload) => {
  //         console.log('payload: ' + payload);
  //         setNotification({
  //           title: payload.notification.title,
  //           body: payload.notification.body,
  //         });
  //         setShow(true);
  //       })
  //       .catch((err) => console.log("failed: ", err));
  //   }
  //   else {
  //     console.log('isTokenFound: ' + isTokenFound);
  //   }
  // }, [isTokenFound]);
  




  return (
    // <Container style={{ width: "400px" }}>
    <Container>
      <HideAppBar />
      <Row>
        <Col>
          <UserAuthContextProvider>
            <Routes>
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route path="/home" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/phonesignup" element={<PhoneSignUp />} />
              <Route path="/listaSeguros" element={<ListaSeguros />} />
              <Route path="/seguros/SeguroDeportesAereos" element={<SeguroDeportesAereos />} />
              <Route path="/seguros/SeguroMontana" element={<SeguroMontana />} />
              <Route path="/seguros/SeguroTaekwondo" element={<SeguroTaekwondo />} />
              <Route path="/Configuracion" element={<Configuración />} />
              <Route index element={<Home></Home>} />
            </Routes>
          </UserAuthContextProvider>
        </Col>
      </Row>
      <CssBaseline />
      <AddToHomeScreen />
      <SimpleBottomNavigation></SimpleBottomNavigation>
    </Container>
  );
}

// console.log('registering service worker');
// serviceWorkerRegistration.register({
//   onUpdate: async (registration) => {
//     // Corremos este código si hay una nueva versión de nuestra app
//     // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       registration.waiting.postMessage({ type: "SKIP_WAITING" });
//       // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
//       window.location.reload();
//     }
//   },
// });

export default App;
