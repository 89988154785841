import React from 'react';

import { FormGroup, Switch, Chip } from '@mui/material';

import { useNavigate } from "react-router-dom";

import {Modal} from '@mui/material';

import { Box, Typography } from '@mui/material';
import { InstallPWA } from './InstallPWA.mjs';

const Configuración = () => {

    const navigate = useNavigate();

    let phoneNumber = localStorage.getItem('phoneNumber');

    const isNotificacionesHabilitadas = () => {
        //is notification granted?
        if (Notification.permission === "granted") {
            //if local storage is not set, set it
            if (localStorage.getItem("notificacionesHabilitadas") === null) {
                localStorage.setItem("notificacionesHabilitadas", true);
            }
            else if (localStorage.getItem("notificacionesHabilitadas") === "true") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const [state, setState] = React.useState({
        notificaciones: isNotificacionesHabilitadas(),
    });

    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });

        console.log('event.target.checked', event.target.checked);

        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });

        //is navigator supported?
        if (!("Notification" in window)) {
            alert("Este navegador no soporta notificaciones. Por favor, intentalo con la versión más reciente de Chrome o Firefox.");
        }
        else {

            if (event.target.checked) {

                console.log('Intento Habilitar notificaciones');

                //habilitar notificaciones
                Notification.requestPermission().then(function (result) {
                    console.log(result);
                    if (result === "granted") {
                        //setState({ ...state, [event.target.name]: event.target.checked });

                        //save in localstorage
                        localStorage.setItem("notificacionesHabilitadas", true);
                    }
                    else {
                        alert('No se han habilitado las notificaciones. Tendrás que hacerlo manualmente en el navegador.');
                    }
                });
            }
            else {
                //deshabilitar notificaciones

                console.log('Intento Deshabilitar notificaciones');

                //setState({ ...state, [event.target.name]: event.target.checked });

                //save in localstorage
                localStorage.setItem("notificacionesHabilitadas", false);
            }
        }
    };

    

    const [openClickChip, setOpen] = React.useState(false);


    const handleClickChip = () => {
        //cont number of clicks
        let clicks = localStorage.getItem('chipClicks');
        if (clicks === null) {
            clicks = 0;
        }
        clicks++;
        localStorage.setItem('chipClicks', clicks);

        //if 5 clicks, show phone number
        if (clicks === 7) {
            setOpen(true);
            localStorage.setItem('chipClicks', 0);
            // alert('Desarrollado por deportenavarro.com');
        }
    }

    const handleChipClose = () => {
        setOpen(false);
    };


    const [openClickInstall, setOpenInstall] = React.useState(false);

    const handleClickInstall = () => {
        //setOpenInstall(true);

        //check if PWA is installed
        if (window.matchMedia('(display-mode: standalone)').matches) {
            alert('Ya tienes instalada la aplicación');
        }
        else {
            // alert('Instala la aplicación para poder usarla sin conexión a internet');

            //navigate('/install');

            //install pwa
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                window.deferredPrompt = null;
            });

            
        }
    }

    const handleCloseInstall = () => {
        setOpenInstall(false);
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    
    // const handleClickChip = () => setOpen(true);


    
    return (
        <div>
            <div className="p-4 box centrado margintop-1">
            <h1 className="mb-3 texto-seguro">Permitir las notificaciones:</h1>
            <FormGroup>
                {/* <FormControlLabel control={<Switch defaultChecked />} checked={state.notificaciones} label="Notificaciones habilitadas" /> */}
                <Switch checked={state.notificaciones} onChange={handleChange} name="notificaciones" />
            </FormGroup>
            </div>
            <div className="p-4 box centrado margintop-1">
            <h1 className="mb-3 texto-seguro">Tu nº de teléfono registrado es el {phoneNumber}. Si deseas cambiarlo, pulsa el botón:</h1>
            <button className="btn btn-primary" onClick={() => navigate('/phonesignup')}>Cambiar teléfono</button>
            </div>
            <InstallPWA></InstallPWA>
            <div className="centrado margintop-1">
                {/* <button className="btn btn-primary" onClick={handleClickInstall}>Instalar aplicación</button> */}
                <Chip label="Licencia Deportiva. Versión 7.13" onClick={handleClickChip} />
            </div>
            <Modal
                open={openClickChip}
                onClose={handleChipClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Licencia Deportiva
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Versión 7.14
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Desarrollado por deportenavarro.com
                    </Typography>
                </Box>
            </Modal>
            {/* <Modal
                open={openClickInstall}
                onClose={handleCloseInstall}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Licencia Deportiva
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Versión 7.12
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Desarrollado por deportenavarro.com
                    </Typography>
                </Box>
            </Modal> */}
        </div>
        
    )
}

export default Configuración;