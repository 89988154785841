import {ListItem, ListItemAvatar, Avatar, ListItemText, List} from '@mui/material';
import {Folder} from '@mui/icons-material';
import React from 'react';

import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import Drafts from '@mui/icons-material';

import { useNavigate } from "react-router-dom";



const ListaSeguros = () => {

    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, value) => {
        // setSelectedIndex(index);
        console.log("index", value);

        navigate("/seguros/Seguro" + value);

        // navigate('/seguros/SeguroDeportesAereos');

    };

    
let tieneDeportesAereos = false;
let tieneTaekwondo = false;
let tieneMontana = false;
let listItems = [];

tieneDeportesAereos = localStorage.getItem("tieneDeportesAereos") === "true";
tieneTaekwondo = localStorage.getItem("tieneTaekwondo") === "true";
tieneMontana = localStorage.getItem("tieneMontana") === "true";


//**** Si solo tiene un seguro, le llevo directamente a ese ****/
let contadorSeguros = 0;
let segurounico = "";
if (tieneDeportesAereos) {
    console.log("tieneDeportesAereos, sumo 1 - " + contadorSeguros);
    contadorSeguros++;
    segurounico = "DeportesAereos";
}
if (tieneTaekwondo) {
    console.log("tieneTaekwondo, sumo 1 - " + contadorSeguros);
    contadorSeguros++;
    segurounico = "Taekwondo";
}
if (tieneMontana) {
    console.log("tieneMontana, sumo 1 - " + contadorSeguros);
    contadorSeguros++;
    segurounico = "Montana";
}

//**** Si solo tiene un seguro, le llevo directamente a ese ****/
if (contadorSeguros === 1) {
    console.log('Solo tiene un seguro');
    navigate("/seguros/Seguro" + segurounico);
}
else {
    console.log('Tiene más de un seguro o ninguno ' + contadorSeguros);
}





if (tieneDeportesAereos) {
    tieneDeportesAereos = true;
    listItems.push(
        <ListItemButton
            onClick={(event) => handleListItemClick(event, 'DeportesAereos')} key="DeportesAereos">
            <ListItemAvatar>
            <Avatar>
                <Folder />
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary="DEPORTES ARÉREOS" />
        </ListItemButton>
    );
}
if (tieneTaekwondo) {
    tieneTaekwondo = true;
    listItems.push(
        <ListItemButton
            onClick={(event) => handleListItemClick(event, 'Taekwondo')} key="Taekwondo">
            <ListItemAvatar>
            <Avatar>
                <Folder />
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary="TAEKWONDO" />
        </ListItemButton>
    );
}
if (tieneMontana) {
    tieneMontana = true;
    listItems.push(
        <ListItemButton
            onClick={(event) => handleListItemClick(event, 'Montana')} key="Montana">
            <ListItemAvatar>
            <Avatar>
                <Folder />
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary="MONTAÑA" />
        </ListItemButton>
    );
}



    

    
    return (
        
        <div className="p-4 box centrado margintop-1">
        <h1 className="mb-3 texto-seguro">Selecciona el seguro que quieres consultar:</h1>
        <List>
            {listItems}
        </List>
        </div>
        
    )
}

export default ListaSeguros;