import { useState, useEffect } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt.mjs';

const webInstallPromptedAt = 'webInstallPromptedAt';

const useWebInstallPrompt = () => {
    const [prompt, setPrompt] = useState(null);
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(
        webInstallPromptedAt
    );
    
    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setPrompt(e);
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);
    
    const handleUserInstallingApp = () => {
        handleUserSeeingInstallPrompt();

        if (prompt) {
            prompt.prompt();
        }

        // prompt.userChoice.then((choiceResult) => {
        //     if (choiceResult.outcome === 'accepted') {
        //         console.log('User accepted the A2HS prompt');
        //     } else {
        //         console.log('User dismissed the A2HS prompt');
        //     }
        // });
    };

    //handleWebInstallDeclined
    const handleWebInstallDeclined = () => {
        console.log('User declined the A2HS prompt');
        return false;
    };

    //handleWebInstallAccepted
    const handleWebInstallAccepted = () => {
        console.log('User accepted the A2HS prompt');
        return true;
    };


    





    
    return [userShouldBePromptedToInstall, handleUserInstallingApp];
};



export default useWebInstallPrompt;