import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getMessaging } from "firebase/messaging/sw";
import { onMessage, getToken } from "firebase/messaging";
import { Http } from "@mui/icons-material";


// import { subscribeToTopic } from "firebase-admin";

const firebaseConfig = {
  apiKey: "AIzaSyBHGhCWhdRLucwCVkjLJGERFravUmN91dA",
  authDomain: "licenciadeportivapwa.firebaseapp.com",
  projectId: "licenciadeportivapwa",
  storageBucket: "licenciadeportivapwa.appspot.com",
  messagingSenderId: "1031435493345",
  appId: "1:1031435493345:web:a1067a3a351dbf9023fca0",
  measurementId: "G-MSZJ8T8Q1B",
  vapidKey: "BG3BQ0DMtoixCBPaQ2jnBbPmN_6xxCTUgwfbuAKGbjzkrHL6ECQhZH-FAyrYhT-rsbPRzzyoJx2vmYjki6moY7g"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

const messaging = getMessaging(app);



//get phone number from auth

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log(user.phoneNumber);
    localStorage.setItem('phoneNumber', user.phoneNumber);
  }
});



//get token for push notification

export const getTokenvalue = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: firebaseConfig.vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured

        var authtoken = localStorage.getItem('token');
        var pushToken = localStorage.getItem('pushToken');

        // var telefono = localStorage.getItem('telefono');

        // if (telefono == null) {
        //   telefono = '';
        // }

        //if currenttoken is equal to token in localstorage, then do nothing
        //if currenttoken is not equal to token in localstorage, then update token in localstorage
        if (pushToken !== currentToken) {
          localStorage.setItem("pushToken", currentToken);
          console.log("token updated in localstorage");
        

        

        // if (authtoken) {
        //   //console.log('token de usuario', authtoken);

        //post to server
          // var url = 'https://fbapi.licenciadeportiva.app/deportesaereos?token='+authtoken+'&pushtoken='+currentToken;
          // var url = 'https://localhost:7002/deportesaereos?token='+authtoken+'&pushtoken='+currentToken;


          //if developer mode is true, then use localhost else use production url
          

          var url = 'https://localhost:7002/notificacionpush?authToken='+authtoken+'&pushtoken='+currentToken;
          var url = 'https://fbapi.licenciadeportiva.app/notificacionpush?authToken='+authtoken+'&pushtoken='+currentToken;

          // fetch(url, {
          //   method: 'POST',
          //   headers: {
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
          //   },
          //   // body: JSON.stringify({
          //   //   token: currentToken,
          //   //   pushToken: authtoken
          //   // })
          // });

          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            },
            body: JSON.stringify({
              authToken: authtoken,
              pushToken: currentToken
            })
          }).then((response) => {
            console.log('response', response);
            // return response.json();

            //if 500 error, then delete token from localstorage and try again
            if (response.status === 500) {
              localStorage.removeItem('pushToken');
              getTokenvalue();
            }
          }).then((data) => {
            console.log('data', data);
          });

        }

        // Subscribe to a topic to receive notifications
        // subscribeToTopic(currentToken, "prueba").then((response) => {
        //   console.log("Successfully subscribed to topic:", response);
        // }).catch((error) => {
        //   console.log("Error subscribing to topic:", error);
        // });

      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

// export const onMessageListener = () =>


//   console.log("onMessageListener DESDE FIREBASE.JS");

//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {

//       console.log('onMessageListener');
//       console.log("payload", payload);


//       resolve(payload);
//     });
//   });



export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });




  // messaging.onBackgroundMessage(function(payload) {
  //   console.log('Received background message ', payload);
  //  // Customize notification here
  //   const notificationTitle = payload.notification.title;
  //   const notificationOptions = {
  //     body: payload.notification.body,
  //   };
  
  //   self.registration.showNotification(notificationTitle,
  //     notificationOptions);
  // });



//   // eslint-disable-next-line no-undef
// importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js");
// // eslint-disable-next-line no-undef
// importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-messaging.js");


// messaging.onBackgroundMessage(function (payload) {
//   console.log("Received background message ", payload);
//   // Customize notification here
//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.notification.body,
//   };

//   // eslint-disable-next-line no-restricted-globals
//   self.registration.showNotification(notificationTitle, notificationOptions);
// });