import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.mjs";

import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

import * as serviceWorkerRegistration from './serviceWorkerRegistration.mjs';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);


// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
//const messaging = firebase.messaging();

console.log('registering service worker');
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});


// const isDebug = false;


// //Mierdas que no se pueden usar en el service worker pq estamos en local
// if (isDebug) {

   const messaging = getMessaging();

  //on push notification received in foreground
  onMessage(messaging, async (payload) => {
    console.log('Message received 4. ', payload);
    //show notification
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: '/icons/android-launchericon-48-48.png'
    };
    //new Notification(notificationTitle, notificationOptions);

    //if service worker is not registered, register it
    // if (!('serviceWorker' in navigator)) {
    //   console.log('Service worker not supported');
    //   return;
    // }

    console.log('intentaré mostrar la notificación');

    try {
      // navigator.serviceWorker.ready.then(function (registration) {
      //   console.log('Intento mostrarla');

      //instance sw registration
       const registration = await navigator.serviceWorker.getRegistration();
       
        registration.showNotification(notificationTitle, notificationOptions);
      // })
      
    } catch (error) {
      console.log('Error al mostrar la notificacion: ' + error);
      new Notification(notificationTitle, notificationOptions);
    }
    
    
  

    // ServiceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
  });
// }
