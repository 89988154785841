import { useState } from 'react';
import moment from 'moment';

// const getInstallPromptLastSeenAt = (promptName: string): string => localStorage.getItem(promptName);
const getInstallPromptLastSeenAt = (promptName) => localStorage.getItem(promptName);


// const setInstallPromptSeenToday = (promptName: string): void => {
//   const today = moment().toISOString();
//   localStorage.setItem(promptName, today);
// };

const setInstallPromptSeenToday = (promptName) => {
    const today = moment().toISOString();
    localStorage.setItem(promptName, today);
};

// function getUserShouldBePromptedToInstall(promptName: string, daysToWaitBeforePromptingAgain: number): boolean {
//   const lastPrompt = moment(getInstallPromptLastSeenAt(promptName));
//   const daysSinceLastPrompt = moment().diff(lastPrompt, 'days');
//   return isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
// }

function getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain) {
    const lastPrompt = moment(getInstallPromptLastSeenAt(promptName));
    const daysSinceLastPrompt = moment().diff(lastPrompt, 'days');

    // console.log ('voy a devolver', isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain);

    return isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
}

// const useShouldShowPrompt = (promptName: string, daysToWaitBeforePromptingAgain = 30): [boolean, () => void] => {
//   const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
//     getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain)
//   );

//   const handleUserSeeingInstallPrompt = () => {
//     setUserShouldBePromptedToInstall(false);
//     setInstallPromptSeenToday(promptName);
//   };

const useShouldShowPrompt = (promptName, daysToWaitBeforePromptingAgain = 30) => {
    const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
        getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain)
    );

    const handleUserSeeingInstallPrompt = () => {
        setUserShouldBePromptedToInstall(false);
        setInstallPromptSeenToday(promptName);
    };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useShouldShowPrompt;